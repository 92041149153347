export const downloadFile  = (fileNameInput:String,data:Blob,fn:any)=>{
    const content = data
    const blob = new Blob([content])
    const fileName = fileNameInput;
    if ('download' in document.createElement('a')) { // 非IE下载
        const elink = document.createElement('a')
        // @ts-ignore
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
    } else { // IE10+下载
        // @ts-ignore
        navigator.msSaveBlob(blob, fileName)
    }
    fn && fn();

}
