<template>
    <a-spin :spinning="loading">
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" />
                <span v-if="id">{{l('Edit')}}</span>
                <span v-else>{{l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-row :gutter="[24, 12]">
                <a-col :span="24">
                    <div style="margin-bottom: 10px"><font color="red">*</font>{{l('Name') + '：'}}</div>
                    <a-input :placeholder="l('CourseCategoryNameInputDesc')" v-model="model.displayName"></a-input>
                </a-col>
                <a-col :span="24" v-if="!id">
                    <div style="margin-bottom: 10px"><font color="red">*</font>{{l('Language_Format') + '：'}}</div>
                    <a-select
                        :allowClear="true"
                        show-search
                        :placeholder="l('Please_enter_a_language_name')"
                        option-filter-prop="children"
                        style="width: 100%"
                        v-model="model.name"
                    >
                        <a-select-option v-for="item in multilingualTypes" :key="item.id" :value="item.name">
                            {{ item.displayName }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </div>
        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save()">
                <a-icon type="save" />
                {{ l('Save') }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { CreateOrUpdateInterfaceInput, QYBLanguageServiceProxy } from '@/shared/service-proxies';

export default {
    mixins: [ModalComponentBase],
    data() {
        return {
            id: undefined,
            editlist: {},
            model: {
                displayName: '',
                name: undefined,
            },
            multilingualTypes: [],
        };
    },
    async mounted() {
        this.fullData();
        this._qybLanguageServiceProxy = new QYBLanguageServiceProxy(this.$apiUrl, this.$api);
        //获取所有语言（下拉框）
        this.multilingualTypes = await this._qybLanguageServiceProxy.getAvaliableLanguages();

        //修改传值
        if (this.id) {
            this.model.displayName = this.editlist.displayName;
        }
    },
    methods: {
        save() {
            if(!this.id){
                if (!this.model.displayName) {
                    abp.message.warn(this.l('CourseCategoryNameInputDesc'));
                    return;
                }
                if (!this.model.name) {
                    abp.message.warn(this.l('Please_enter_a_language_name'));
                    return;
                }
                this.loading = true;
                const input = {};
                input.displayName = this.model.displayName;
                input.name = this.model.name;

                this._qybLanguageServiceProxy
                    .createLanguage(input)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        this.success(true);
                        this.$notification['success']({
                            message: this.l('SavedSuccessfully'),
                        });
                    });
            }else {
                if (!this.model.displayName) {
                    abp.message.warn(this.l('CourseCategoryNameInputDesc'));
                    return;
                }
                this.loading = true;
                this._qybLanguageServiceProxy
                    .changeLanguageDisplayName({"languageId": this.id, "displayName": this.model.displayName})
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        this.success(true);
                        this.$notification['success']({
                            message: this.l('SavedSuccessfully'),
                        });
                    });
            }
        },
    },
};
</script>

<style lang="less" scoped></style>
