<template>
    <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input v-model="myVal" @pressEnter="check" style="color: #1890ff;" />
          <a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper" style="color: #1890ff;">
          {{ record.targetValue }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
</template>
<script>
export default{
    props: {
    record:{
      type:Object,
      default:()=>{}
    },
  },
  data() {
    return {
      editable: false,
      myVal:''
    };
  },
  watch:{
      'record.targetValue'(nval){
        this.myVal = nval
      },
      'record.id'(naval,oval){
        if(nval!=oval){
          this.editable = false;
          this.myVal = this.record.targetValue
        }
      },
    'record.editable'(nval,oval){
        if(nval === false){
          this.editable = false;
          this.myVal = this.record.targetValue
        }
    }
  },
  methods: {
    check() {
      this.editable = false;
      this.$emit('rowchange', { value: this.myVal, record: Object.assign(this.record,{editable:this.editable}) });
    },
    edit() {
      this.editable = true;
      this.myVal = this.record.targetValue;
      this.$emit('edit',this.record)
    }
  },
}
</script>