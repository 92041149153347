<template>
    <a-spin :spinning="loading">
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" />
                <span> &nbsp;{{ zname + ' ' + l('Configure_The_Subkey')}}</span>
            </div>
        </div>
        <div>
            <a-row :gutter="[24, 12]">
                <a-col :span="8">
                    <div style="height: 50px; float: left; line-height: 50px;">
                        <label>{{l('BaseLanguage') + '：'}}</label>
                    </div>
                    <div style="height: 50px; width: 70%; float: left; line-height: 50px;">
                        <a-select
                            show-search
                            :placeholder="l('Please_select_the_source_language')"
                            option-filter-prop="children"
                            style="width: 100%"
                            v-model="model.name"
                            @change="onchange"
                        >
                            <a-select-option v-for="item in multilingualTypes" :key="item.id" :value="item.id">
                                {{ item.displayName }}
                            </a-select-option>
                        </a-select>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div style="height: 50px; line-height: 50px;">
                        <label>{{l('Target_Language') + '：'}}<a-tag style="font-weight: 700; font-size: 0.8rem;" color="blue">{{ zname }}</a-tag></label>
                    </div>
                </a-col>
                <a-col :span="10">
                    <div style="height: 50px; line-height: 50px; text-align: right;">
                        <a-input-search :placeholder="l('PostContentInputDesc')" allow-clear style="width: 200px; margin-right: 10px;" @search="onSearch" />
                        <a-button @click="addExcel" v-if="zabptenant !== undefined && zisTenantLanguage || zabptenant === undefined && !zisTenantLanguage">
                            <a-icon type="file-excel" />
                            <span>{{l('Import')}}</span>
                            <input ref="exceluploadinput" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick" />
                        </a-button>
                        <a-button @click="outExcel">
                            <a-icon type="download" />
                            <span>{{l('Export')}}</span>
                        </a-button>
                    </div>
                </a-col>
                
                <a-col :span="24">
                    <a-table class="list-table" :pagination="true" :customRow="customRow" :columns="keycolumns"
                            :rowKey="(tableDatas) => tableDatas.id" :dataSource="tableData" >
                        <template slot-scope="text, record" slot="targetValue">
                            <edittable :key="record.id" :record="record" @rowchange="onCellChange" @edit="handleEdit" v-if="zabptenant !== undefined && zisTenantLanguage || zabptenant === undefined && !zisTenantLanguage" />
                            <span style="color: #1890ff;" v-else>{{ text }}</span>
                        </template>
                    </a-table>
                </a-col>
            </a-row>
        </div>
    </a-spin>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { QYBLanguageServiceProxy, EditTranslateLanguageReq, SaveLanguageTextReq } from '@/shared/service-proxies';
import edittable from '../components/editable.vue';
import { fileDownloadService } from '@/shared/utils';
import {downloadFile} from "@/shared/utils/download-file";

export default {
    mixins: [ModalComponentBase],
    components: {
        edittable,
    },
    data() {
        return {
            id: undefined,
            model: {
                displayName: '',
                name: undefined,
            },
            multilingualTypes: [],
            keycolumns: [
                {
                    title: this.l('Key'),
                    dataIndex: 'key',
                    sorter: false,
                    ellipsis: true,
                    width: 120,
                    align: 'center',
                    scopedSlots: { customRender: 'key' },
                },
                {
                    title: this.l('BaseValue'),
                    dataIndex: 'value',
                    sorter: false,
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'value' },
                },
                {
                    title: this.l('TargetValue'),
                    dataIndex: 'targetValue',
                    sorter: false,
                    width: 140,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'targetValue' },
                },
            ],
            loading: false,
            tableData:[],
            selectTableData:[],
            targetid: undefined,
            rowlanguageTexts: [],
            _fileDownloadService: undefined, //导出
            language: {},
            zname: undefined,
            zabptenant: undefined,
            zisTenantLanguage: undefined,
        };
    },
    async mounted() {
        this.fullData();
        this._qybLanguageServiceProxy = new QYBLanguageServiceProxy(this.$apiUrl, this.$api);
        //获取所有语言（下拉框）
        this.multilingualTypes = await this._qybLanguageServiceProxy.getLanguages();
        this._fileDownloadService = fileDownloadService;
        
        this.model.name = this.language[0].displayName;
        this.onchange(this.language[0].id);

        //父级传来的值
        this.zname = this.Name;
        this.zabptenant = this.abptenant;
        this.zisTenantLanguage = this.isTenantLanguage;
    },
    methods: {
        onCellChange(e){
            this.loading = true;
            var list = {};
            list.key = e.record.key;
            list.value = e.record.value;
            list.targetValue = e.value;

            var req = { languageId: this.Id, languageTexts: [ list ] }
            
            this._qybLanguageServiceProxy
                .saveLanguageOneText(req)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.$notification['success']({
                        message: this.l('SavedSuccessfully'),
                    });
                    const find = this.tableData.find(c=>c.key === list.key && c.value === list.value);
                    find.targetValue = e.value;
                    find.editable = e.record.editable;
                });
        },
      handleEdit(record){
        const find = this.tableData.find(c=>c.key === record.key && c.value === record.value);
        find.editable = true;
      },
        onchange(e){
            if(e === this.Id){
                abp.message.warn(this.l('The_target_language_cannot_be_the_same_as_the_source_language'));
                this.model.name = undefined;
                return;
            }
            this.loading = true;
            this.targetid = e;
            var req = new EditTranslateLanguageReq({ sourceLanguageId: e, targetLanguageId: this.Id })
            this._qybLanguageServiceProxy
                .queryLanguageTranslate(req)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    //展示
                    this.tableData = res.languageTexts.map(c=>Object.assign(c,{editable:false}));
                    //前端查询
                    this.selectTableData = res.languageTexts;
                });
        },
        onSearch(es){
            //前端查询
            if(this.targetid === undefined){
                abp.message.warn(this.l('Please_select_the_source_language') + '！');
                return;
            }
            if(es){
              es = es.trim();
                let datas = this.selectTableData.filter((txt) => {
                    return txt.value.indexOf(es) !== -1 || txt.key.indexOf(es) !== -1 || txt.targetValue.indexOf(es) !== -1
                });
                this.tableData = datas.map(c=>Object.assign(c,{editable:false}));
            }else {
                this.onchange(this.targetid);
            }
        },
        addExcel() {
            //修复无法选择相同的 Excel
            this.$refs['exceluploadinput'].value = null
            if(this.targetid === undefined){
                abp.message.warn(this.l('Please_select_the_source_language') + '！');
                return;
            }else {
                this.$refs['exceluploadinput'].click();
            }
        },
        handleClick(e){
            const files = e.target.files;
            const rawFile = files[0];
            var exname = files[0].name;
            if (!this.isExcel(rawFile)) {
                this.$message.error(this.l('Uploads_are_only_supported') + ' .xlsx .xls .csv' + this.l('Suffix_File') +'!')
                return;
            }
            this.loading = true;
            this._qybLanguageServiceProxy.importLanguage(this.targetid, {data:rawFile, fileName: exname})
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.$notification['success']({
                      message: this.l('The_impor_ was_successful'),
                    });
                    this.onchange(this.targetid);
                });
        },
        isExcel(file) {
            return /\.(xlsx|xls|csv)$/.test(file.name)
        },
        outExcel() {
            if(this.targetid === undefined){
                abp.message.warn(this.l('Please_select_the_source_language') + '！');
                return;
            }
            this.loading = true;
            this._qybLanguageServiceProxy.exportLanguage(this.Id, this.targetid)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                  if(res.status === 200){
                    downloadFile(this.zname + this.l('host-10') + ".xlsx",res.data,null)
                    this.$notification['success']({
                      message: this.l('Export_successful'),
                    });
                  }
                });
        },
    },
};
</script>

<style lang="less" scoped>

.excel-upload-input{
  display: none;
  z-index: -9999;
}
</style>
