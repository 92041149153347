<template>
    <a-config-provider :locale="zh_CN" :spinning="loading">
      <div class="my-md">
        <a-row :gutter="8">
        <a-col :span="7">
              <q-tree-container>
                  <template #select>
            <div style="height: 50px; line-height: 70px;">
              <a-button :type="'primary'" @click="createInterface">
                  创建
              </a-button>
            </div>
            
          </template>
                  <template #tree>
            <!-- <a-tree
                          :tree-data="treeData"
                          @select="onSelect"
                          :expanded-keys="expandedKeys"
                          @expand="onExpand"
                          :selectedKeys="TreeKey"
                      >
                          <a-icon type="folder" slot="folder" />
                          <a-icon type="file" slot="file" />
                          <a-icon type="folder-open" slot="folder-open" />
                          <template slot="custom" slot-scope="{ expanded }">
                              <a-icon :type="expanded ? 'folder-open' : 'folder'" />
                          </template>
                      </a-tree> -->
            <a-table :loading="loading"
                :showHeader="true"
                size="middle"
                :pagination="false"
                :customRow="customRow"
                :columns="columns"
                :rowKey="(tableDatas) => tableDatas.id"
                :dataSource="tableData"
                :rowClassName="selectColor"
              >
                <a-checkbox
                  slot="checkbox"
                  slot-scope="text, record"
                  :checked="record.checked"
                >
                </a-checkbox>
                <template slot-scope="text, record" slot="displayName">
                  {{ record.displayName
                  }}<a-tag v-if="!record.isTenantLanguage" color="blue"
                    >系统</a-tag
                  >
                </template>
  
                <template slot-scope="text, record" slot="Actions">
                  <a-row>
                    <a-col :span="6">
                      <a-switch
                        checked-children="启用"
                        un-checked-children="关闭"
                        default-checked
                        :checked="!record.isDisabled"
                        @click="swChange($event, record)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-switch
                        checked-children="默认"
                        un-checked-children="取消"
                        default-checked
                        :checked="record.isDefault"
                        @click="defaultChange($event, record)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a @click="editmultilingual(record)">
                        <a-icon type="edit" />
                        <span> 修改 </span>
                      </a>
                    </a-col>
                    <a-col :span="6">
                      <a-popconfirm
                        placement="top"
                        :okText="l('Ok')"
                        :cancelText="l('Cancel')"
                        @confirm="deleteItem(record)"
                      >
                        <template slot="title">{{
                          l("ConfirmDeleteWarningMessage")
                        }}</template>
                        <a>
                          <a-icon type="delete" />
                          <span>{{ l("Delete") }}</span>
                        </a>
                      </a-popconfirm>
                    </a-col>
                  </a-row>
                  <!-- <a @click="editdisposition(record)">
                    <a-icon type="edit" />
                    <span> 配置子项 </span>
                  </a> -->
                </template>
              </a-table>
                  </template>
              </q-tree-container>
          </a-col>
      <a-col :span="17">
              <!-- usePagination开启分页 -->
              <!-- loading绑定引入页面的蒙层加载变量 -->
              <q-container usePagination :loading="loading">
          <multilingualdispositionIndex ref="EditKey"></multilingualdispositionIndex>
        </q-container>
      </a-col>
      </a-row>
      </div>
      
    </a-config-provider>
  </template>
  
  <script>
  import { AppComponentBase } from "@/shared/component-base";
  import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
  import {
    QYBLanguageServiceProxy,
    ChangeLanguageStatusReq,
  } from "@/shared/service-proxies";
  import multilingualadd from "./multilingual-add/multilingual-add.vue";
  import multilingualdisposition from "./multilingual-disposition/multilingual-disposition.vue";
  import multilingualdispositionIndex from "./multilingual-disposition/Index.vue";
  import ModalHelper from "@/shared/helpers/modal/modal-helper";
  import { abpService } from "@/shared/abp";
  let _this;
  export default {
    name: "multilingual-setting",
    mixins: [AppComponentBase],
    components: { multilingualdispositionIndex },
    created() {
      _this = this;
      this._qybLanguageServiceProxy = new QYBLanguageServiceProxy(
        this.$apiUrl,
        this.$api
      );
      this.abptenant = abpService.loginInfo.tenant;
    },
    data() {
      return {
        LangID: undefined, ///选 中的语言包
  
        zh_CN,
        // 总数
        totalItems: 0,
        // 当前页码
        pageNumber: 1,
        // 每页多少条数据
        pageSize: 10,
        filterText: "",
        //语言树
              treeData: [],
        columns: [
          {
            title: "语言名称",
            dataIndex: "displayName",
            sorter: false,
            ellipsis: true,
            width: 60,
            align: "center",
            scopedSlots: { customRender: "displayName" },
          },
          {
            title: "操作",
            dataIndex: "Actions",
            sorter: false,
            align: "center",
            width: 170,
            scopedSlots: { customRender: "Actions" },
          },
        ],
        keycolumns: [
          {
            title: "键值",
            dataIndex: "displayName",
            sorter: false,
            ellipsis: true,
            width: 120,
            align: "center",
            scopedSlots: { customRender: "displayName" },
          },
          {
            title: "默认值",
            dataIndex: "name",
            sorter: false,
            width: 140,
            ellipsis: true,
            align: "center",
            scopedSlots: { customRender: "name" },
          },
          {
            title: "目标值",
            dataIndex: "name",
            sorter: false,
            width: 140,
            ellipsis: true,
            align: "center",
            scopedSlots: { customRender: "name" },
          },
          {
            title: this.l("Actions"),
            dataIndex: "Actions",
            sorter: false,
            align: "center",
            width: 130,
            scopedSlots: { customRender: "Actions" },
          },
        ],
        tableData: [],
        keytableData: [],
        selectedRowKeysWW: [], // 选择的数据
        _qybLanguageServiceProxy: undefined,
        selectedRow: {},
        //当前选中的列ID
        multilingualId: undefined,
        loading: true,
        ischecked: undefined,
        isdefaultlanguage: undefined,
        abptenant: undefined, //判断是否是租户
        switchbtn: undefined,
        TreeKey: undefined,
      };
    },
    mounted() {
      _this = this;
  
      //获取语言分类
      this.getdata();
    },
    methods: {
      //语言切换
      CheckLang(it) {
        this.LangID = it.id;
        this.$refs.EditKey.onchange(this.LangID);
      },
  
      ///获取语言分类
      getdata() {
        this.selectedRowKeysWW = [];
        this.loading = true;
        this._qybLanguageServiceProxy
          .getLanguages()
          .finally(() => {
            this.loading = false;
          })
          .then((res) => {
            this.tableData = res;
            this.LangID = this.tableData[0].id;
            this.selectedRowKeysWW.push(res[0].id);
            this.$refs.EditKey.onchange(this.LangID);
          });
      },
      getdataHide(id) {
        this.loading = true;
        this._qybLanguageServiceProxy
          .getLanguages()
          .finally(() => {
            this.loading = false;
          })
          .then((res) => {
            this.tableData = res;
            this.LangID = id;
            this.selectedRowKeysWW.push(id);
            this.$refs.EditKey.onchange(this.LangID);
          });
      },
      //启用按钮
      swChange(s, e) {
        const language = localStorage.getItem("qyb-language");
        if (e.name === language && !e.isDisabled) {
          abp.message.warn("当前使用的语言不能关闭");
          return;
        }
        var qystate = false;
        if (!e.isDisabled) {
          qystate = true;
        }
        this.loading = true;
        var req = new ChangeLanguageStatusReq({
          language: e.id,
          isDisabled: qystate,
        });
        this._qybLanguageServiceProxy
          .enableLanguage(req)
          .finally(() => {
            this.loading = false;
          })
          .then((res) => {
            this.getdata();
            this.$notification["success"]({
              message: "操作成功",
            });
          });
      },
      //设置默认语言按钮
      defaultChange(s, e) {
        if (!s) {
          abp.message.warn("请选择其他语言");
          return;
        }
        this.loading = true;
        var req = { id: e.id };
        this._qybLanguageServiceProxy
          .defaultLanguage(req)
          .finally(() => {
            this.loading = false;
          })
          .then((res) => {
            //this.getdata();
            this.getdataHide(e.id);
            this.$notification["success"]({
              message: "操作成功",
            });
          });
      },
      selectColor(record,index) {
        if (record.id === this.selectedRowKeysWW[0]) {
            return 'select-color'
        }
      },
      /**
       * 表格行点击
       * @param record 点击行的数据
       * @param index 点击行的下标
       */
      customRow: (record, index) => ({
        on: {
          click: () => {
            _this.multilingualId = record.id;
            //清空选择
            _this.tableData.map((item) => (item.checked = false));
            // //赋值选择
            // record.checked = !record.checked;
            //强制更新布局
            _this.$forceUpdate();
            _this.editdisposition(record);
          },
        },
      }),
      //配置子项
      editdisposition(list) {
        console.log(list)
        this.$refs.EditKey.onchange(list.id);
        list.checked = true;
        if (list.checked) {
            this.selectedRowKeysWW = [];
            this.selectedRowKeysWW[0] = list.id;
        } else {
            this.selectedRowKeysWW = [];
        }
        // if(!list.isTenantLanguage && this.abptenant !== undefined){
        //     abp.message.warn('租户不能修改系统语言！');
        //     return;
        // }
        //   const language = localStorage.getItem("qyb-language");
        //   let datas = this.tableData.filter((txt) => {
        //     if (language === txt.name) {
        //       return txt;
        //     }
        //   });
        //   ModalHelper.create(
        //     multilingualdisposition,
        //     //传入配置子项组件数据
        //     {
        //       Id: list.id,
        //       Name: list.displayName,
        //       language: datas,
        //       isTenantLanguage: list.isTenantLanguage,
        //       abptenant: this.abptenant,
        //     },
        //     {
        //       width: "1200px",
        //     }
        //   ).subscribe((res) => {
        //     if (res) {
        //       this.getdata();
        //     }
        //   });
      },
      //添加语言
      createInterface() {
        ModalHelper.create(
          multilingualadd,
          {},
          {
            width: "400px",
          }
        ).subscribe((res) => {
          if (res) {
            this.getdata();
          }
        });
      },
      //修改按钮
      editmultilingual(item) {
        if (!item.isTenantLanguage && this.abptenant !== undefined) {
          abp.message.warn("租户不能修改系统语言！");
          return;
        }
        ModalHelper.create(
          multilingualadd,
          { id: item.id, editlist: item },
          {
            width: "400px",
          }
        ).subscribe((res) => {
          if (res) {
            this.getdataHide(item.id);
          }
        });
      },
      //删除
      deleteItem(item) {
        this.loading = true;
        if (item.isDefault === true) {
          abp.message.warn("设置默认的语言不能删除！");
          this.loading = false;
          return;
        }
        if (!item.isTenantLanguage && this.abptenant !== undefined) {
          abp.message.warn("租户不能删除系统语言！");
          this.loading = false;
          return;
        }
        this._qybLanguageServiceProxy
          .deleteLanguage(item.id)
          .finally(() => {
            this.loading = false;
          })
          .then((res) => {
            this.getdata();
            this.$notification["success"]({
              message: "删除成功",
            });
          });
      },
    },
  };
  </script>
  
  <style>
  </style>
  
  <style lang="less" scoped>
  .langBox {
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    padding-left: 10px;
  }
  /deep/.select-color {
      font-weight: 600;
      background: #b9e4f8;
  }
  .cur {
    background-color: #8fc7e8;
    color: #fff;
  }
  </style>