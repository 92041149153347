<template>
    <a-spin :spinning="loading">
        <div style="height: 100%;">
            <a-row>
                <a-col :span="12">
                    <div>
                        <a-row :gutter="6" style="height: 50px; line-height: 50px;">
                            <a-col :span="4" style="text-align: center;">
                                <label>参考语言</label>
                            </a-col>
                            <a-col :span="6">
                                <a-select show-search placeholder="参考语言" option-filter-prop="children"
                                    style="width: 100%; height: 32px;" v-model="languageId" @change="onYchange">
                                    <a-select-option v-for="item in selectlanguagelist" :key="item.id" :value="item.id">
                                        {{ item.displayName }}
                                    </a-select-option>
                                </a-select>
                            </a-col>
                            <a-col :span="4">
                                <a-button @click="addExcel" style="width: 100%; text-align: center;"
                                    v-if="(zabptenant !== undefined && zisTenantLanguage) || (zabptenant === undefined && !zisTenantLanguage)">
                                    <a-icon type="download" />
                                    <span>导入</span>
                                    <input ref="exceluploadinput" class="excel-upload-input" type="file" accept=".xlsx, .xls"
                                        @change="handleClick" />
                                </a-button>
                            </a-col>
                            <a-col :span="4">
                                <a-button style="width: 100%; text-align: center;" @click="outExcel">
                                    <a-icon type="file-excel" />
                                    <span>导出</span>
                                </a-button>
                            </a-col>
                        </a-row>
                        <!-- <label
                            >目标语言：<a-tag
                                style="font-weight: 700; font-size: 0.8rem"
                                color="blue"
                                >{{ localelanguage.displayName }}</a-tag
                            ></label
                        > -->
                    </div>
                </a-col>

                <a-col :span="12">
                    <div style="line-height: 50px;">
                        <a-row :gutter="6">
                            <a-col :span="6" :push="10">
                                <a-select :allowClear="true" v-model="TypeLanguage" style="width: 100%;"
                                    @change="handleChange" placeholder="文本类型">
                                    <a-select-option value="Menu.">
                                        菜单
                                    </a-select-option>
                                    <a-select-option value="Dic.">
                                        字典
                                    </a-select-option>
                                </a-select>
                            </a-col>
                            <a-col :span="8" :push="10">
                                <a-input-search placeholder="请输入内容" allow-clear style="width: 100%;" @search="onSearch" />
                            </a-col>
                        </a-row>
                    </div>
                </a-col>

                <a-col :span="24">
                    <a-table size="middle" class="list-table" :pagination="true" :customRow="customRow"
                        :columns="keycolumns" :rowKey="(tableDatas) => tableDatas.id" :dataSource="tableData">
                        <template slot="valueTitle">{{ selectlanguagedisplayName }}</template>
                        <template slot="targetValueTitle">{{ localelanguage.displayName }}</template>
                        <template slot-scope="text, record" slot="targetValue">
                            <edittable :key="record.id" :record="record" @rowchange="onCellChange" @edit="handleEdit" v-if="
                                (zabptenant !== undefined &&
                                    zisTenantLanguage) ||
                                (zabptenant === undefined &&
                                    !zisTenantLanguage)
                            " />
                            <span style="color: #1890ff" v-else>{{
                                text
                            }}</span>
                        </template>
                    </a-table>
                </a-col>
            </a-row>
        </div>
    </a-spin>
</template>

<script>
import ModalComponentBase from "@/shared/component-base/modal-component-base";
import {
    QYBLanguageServiceProxy,
    EditTranslateLanguageReq,
    SaveLanguageTextReq,
} from "@/shared/service-proxies";
import edittable from "../components/editable.vue";
import { fileDownloadService } from "@/shared/utils";
import { downloadFile } from "@/shared/utils/download-file";

export default {
    mixins: [ModalComponentBase],
    components: {
        edittable,
    },
    data() {
        return {
            id: undefined,
            TypeLanguage: undefined,
            multilingualTypes: [],
            keycolumns: [
                {
                    title: "键值",
                    dataIndex: "key",
                    sorter: false,
                    ellipsis: true,
                    width: 120,
                    align: "left",
                    scopedSlots: { customRender: "key" },
                },
                {
                    dataIndex: "value",
                    sorter: false,
                    width: 140,
                    ellipsis: true,
                    align: "left",
                    scopedSlots: { customRender: "value", title: 'valueTitle' },
                },
                {
                    dataIndex: "targetValue",
                    sorter: false,
                    width: 140,
                    ellipsis: true,
                    align: "left",
                    scopedSlots: { customRender: "targetValue", title: 'targetValueTitle' },
                },
            ],
            loading: false,
            tableData: [],
            selectTableData: [],
            targetid: undefined,
            rowlanguageTexts: [],
            _fileDownloadService: undefined, //导出
            language: {},
            zname: undefined,
            zabptenant: undefined,
            zisTenantLanguage: undefined,
            Data: undefined,

            Id: undefined,
            languageId: undefined,
            localelanguage: {},
            selectlanguagelist: [],
            selectlanguagedisplayName: '',
        };
    },

    //props: ["Id"],

    async mounted() {
        this.fullData();
        this._qybLanguageServiceProxy = new QYBLanguageServiceProxy(
            this.$apiUrl,
            this.$api
        );
        //获取所有语言（下拉框）
        this.multilingualTypes =
            await this._qybLanguageServiceProxy.getLanguages();
        this._fileDownloadService = fileDownloadService;

        //  this.model.name = this.language[0].displayName;
        //  this.onchange(this.language[0].id);

        // this.onchange(this.Id);

        //父级传来的值
        this.zname = this.Name;
        this.zabptenant = this.abptenant;
        this.zisTenantLanguage = this.isTenantLanguage;
    },
    methods: {
        onYchange(cid) {
            this.onchange(cid);
        },
        Headers(id) {
            this.localelanguage = this.multilingualTypes.find((n) => {
                if (n.id == id) {
                    return n;
                }
            });
            this.selectlanguagelist = this.multilingualTypes.filter(
                (item, index, arry) => {
                    if (item.id != id) {
                        return item;
                    }
                }
            );
            this.languageId = this.selectlanguagelist[0].id;
            this.selectlanguagedisplayName = this.selectlanguagelist[0].displayName;
        },
        handleChange() {
            this.onSearch(this.TypeLanguage);
        },
        onCellChange(e) {
            this.loading = true;
            var list = {};
            list.key = e.record.key;
            list.value = e.record.value;
            list.targetValue = e.value;

            var req = { languageId: this.Id, languageTexts: [list] };

            this._qybLanguageServiceProxy
                .saveLanguageOneText(req)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.$notification["success"]({
                        message: this.l("SavedSuccessfully"),
                    });
                    const find = this.tableData.find(
                        (c) => c.key === list.key && c.value === list.value
                    );
                    find.targetValue = e.value;
                    find.editable = e.record.editable;
                });
        },
        handleEdit(record) {
            const find = this.tableData.find(
                (c) => c.key === record.key && c.value === record.value
            );
            find.editable = true;
        },
        onchange(e) {
            this.loading = true;
            this.targetid = e;

            this.Id = e;

            var tempid = this.Id;

            if (!this.Id) {
                tempid = e;
            }
            this.Headers(tempid);

            var req = new EditTranslateLanguageReq({
                sourceLanguageId: this.languageId,
                targetLanguageId: tempid,
            });
            this._qybLanguageServiceProxy
                .queryLanguageTranslate(req)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    console.log("shuju:",res)
                    if (res.languageTexts) {
                        this.tableData = res.languageTexts.map((c) =>
                            Object.assign(c, { editable: false })
                        );
                    }
                    //展示

                    //前端查询
                    this.selectTableData = res.languageTexts;
                });
        },
        onSearch(es) {
            //前端查询
            if (this.targetid === undefined) {
                abp.message.warn("请选择源语言！");
                return;
            }
            if (es) {
                es = es.trim();
                let datas = this.selectTableData.filter((txt) => {
                    return (
                        txt.value.indexOf(es) !== -1 ||
                        txt.key.indexOf(es) !== -1 ||
                        txt.targetValue.indexOf(es) !== -1
                    );
                });
                this.tableData = datas.map((c) =>
                    Object.assign(c, { editable: false })
                );
            } else {
                this.onchange(this.targetid);
            }
        },
        addExcel() {
            //修复无法选择相同的 Excel
            this.$refs["exceluploadinput"].value = null;
            if (this.targetid === undefined) {
                abp.message.warn("请选择源语言！");
                return;
            } else {
                this.$refs["exceluploadinput"].click();
            }
        },
        handleClick(e) {
            const files = e.target.files;
            const rawFile = files[0];
            var exname = files[0].name;
            if (!this.isExcel(rawFile)) {
                this.$message.error("仅支持上传 .xlsx .xls .csv后缀文件!");
                return;
            }

            this.loading = true;
            this._qybLanguageServiceProxy
                .importLanguage(this.Id, { data: rawFile, fileName: exname })
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.$notification["success"]({
                        message: "导入成功",
                    });
                    this.onchange(this.targetid);
                });
        },
        isExcel(file) {
            return /\.(xlsx|xls|csv)$/.test(file.name);
        },
        outExcel() {
            if (this.targetid === undefined) {
                abp.message.warn("请选择源语言！");
                return;
            }
            this.loading = true;
            this._qybLanguageServiceProxy
                .exportLanguage(this.Id, this.targetid)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    if (res.status === 200) {
                        downloadFile(
                            this.zname + "多语言.xlsx",
                            res.data,
                            null
                        );
                        this.$notification["success"]({
                            message: "导出成功",
                        });
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.excel-upload-input {
    display: none;
    z-index: -9999;
}
</style>
